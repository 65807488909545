.form-page {
    width: 90vw;
    padding: 1rem 0;
    margin: 0 auto;

    h3 {
        font-family: 'Rockford Sans Bold';
        font-size: 2em;
        margin-bottom: 1.5rem;
    }

    label {
        font-family: 'Rockford Sans Regular';
        font-size: 1.5em;
        margin-bottom: 0.5rem;
        line-height: 1.2;

        span {
            color: red;
            font-size: 1rem;
            position: absolute;
            padding-left: 1px;
        }
    }

    input,
    select {
        font-family: 'Montserrat Medium';
        font-size: 1rem;
        color: black;
        height: calc(1.25em + 14px);

        &:focus,
        &:focus-within,
        &:focus-visible {
            color: black;
        }
    }

    .PhoneInput {
        .PhoneInputCountry {
            padding: 0 0.5em;
            background-color: #e9ecef;
            border: 1px solid #ced4da;
            border-radius: 0.25rem 0 0 0.25rem;
            margin: 0;

            .PhoneInputCountrySelectArrow {
                color: black;
                opacity: 1;
                width: 0.5em;
                height: 0.5em;
                margin-left: 0.5em;
                margin-bottom: 0.3em;
                border-style: solid;
                border-width: 0 1px 1px 0;
            }
        }

        .PhoneInputInput {
            display: block;
            background-color: #ffffff;
            border: 1px solid #ced4da;
            border-radius: 0 0.25rem 0.25rem 0;
            padding: 0.375rem 0.75rem;
            -webkit-appearance: none;
        }
    }

    .input-group-prepend {
        height: calc(1.25em + 14px);
    }

    .input-group-text {
        font-family: 'Montserrat SemiBold';
        color: rgba(0, 0, 0, 0.8);
    }

    @media screen and (min-width: 768px) {
        h3 {
            font-size: 40px;
        }

        width: 500px;
    }
}
