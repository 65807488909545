@use '../scss/variables' as *;

#review-page {
    width: 90vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    h3 {
        font-family: 'Rockford Sans Bold';
        font-size: 2em;
        margin-bottom: 1.5rem;
        padding-top: 1rem;
    }

    h5 {
        font-family: 'Rockford Sans Regular';
        font-size: 1.5em;
    }

    p {
        font-family: 'Montserrat Regular';
        font-size: 1em;
        margin-bottom: 0.5rem;
    }

    #details {
        flex-grow: 1;
    }

    #submit-btn {
        font-size: 25px;
        line-height: 35px;
        text-transform: uppercase;

        .loading {
            width: 32px;
            height: 32px;
            position: absolute;
            margin-top: -16px;
            margin-left: -16px;
            border: 2px solid #000;
            border-top-color: rgba(255, 255, 255, 0.15);
            border-radius: 50%;
            animation: loading 1s infinite linear;

            @keyframes loading {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }

        svg {
            margin-left: 5px;
            vertical-align: sub;
        }

        &:hover,
        &:active,
        &:focus {
            background-color: $btn-bg-color !important;
        }
    }

    @media screen and (min-width: 768px) {
        width: 500px;
    }
}
