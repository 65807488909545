#visitor-form {
    padding: 0;
    min-height: auto;
}

#landing-page {
    padding: 1.5rem 0.5rem;

    h3 {
        font-family: 'Rockford Sans Medium';
        font-size: 12vw;
    }

    h5 {
        font-family: 'Montserrat Medium';
        font-size: 7vw;
        margin-top: 10vh;
        padding: 0 0.5rem;
    }

    @media screen and (min-width: 768px) {
        h3 {
            font-size: 72px;
        }

        h5 {
            font-size: 32px;
            width: 500px;
            margin: 50px auto 0;
        }
    }
}
