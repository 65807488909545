@use 'scss/variables' as *;

#visitor-form {
    min-height: 90%;
    display: flex;
    flex-direction: column;
}

#recaptcha > div > div {
    display: flex;
    justify-content: center;
}

.btn {
    background-color: $btn-bg-color;
    color: black;
    border: 0;
    border-radius: 10px;
    height: 56px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    font-family: 'Rockford Sans Medium';

    &:hover,
    &:active,
    &:focus {
        background-color: $btn-bg-color !important;
        color: black !important;
    }
}

#start-btn,
#next-btn {
    width: 90vw;
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 25px;
    line-height: 35px;
    text-transform: uppercase;

    &.disabled,
    &:disabled {
        color: black;
        background-color: $btn-bg-color;
    }

    svg {
        margin-left: 5px;
        vertical-align: sub;
    }

    @media screen and (min-width: 576px) {
        width: 500px;
        bottom: 51px !important;
    }
}

#next-btn {
    .loading {
        width: 32px;
        height: 32px;
        position: absolute;
        margin-top: -16px;
        margin-left: -16px;
        border: 2px solid #000;
        border-top-color: rgba(255, 255, 255, 0.15);
        border-radius: 50%;
        animation: loading 1s infinite linear;

        @keyframes loading {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }

    &.fetch-page {
        @media screen and (max-height: 550px) {
            position: absolute;
        }
    }

    @media screen and (max-height: 550px) {
        position: relative;
    }

    @media screen and (min-width: 576px) {
        width: 500px;
        bottom: 51px !important;

        @media screen and (max-height: 632px) {
            position: relative;
        }
    }
}
