@use 'scss/variables' as *;

html,
body {
    height: 100%;
    margin: 0;
}

body {
    background-color: $bg-color;
    color: $text-color;
    overflow: hidden;
}

:root {
    --PhoneInput-color--focus: black !important;
}

#root {
    height: 100%;
    position: relative;
}

#watermark {
    position: fixed;
    overflow: hidden;
    width: 650vw;
    left: -190vw;
    top: -100vw;
    mix-blend-mode: color-dodge;
    opacity: 0.08;
    transform: rotate(16.78deg);
    z-index: -999;
    transition: left 200ms ease-in-out;
}
