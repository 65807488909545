#thank-you-page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;

    h3 {
        font-family: 'Rockford Sans Light';
        font-size: 8vw;
    }

    #home-btn {
        font-family: 'Rockford Sans Regular';
        font-size: 16px;
        text-transform: uppercase;
        width: 50vw;
        height: 40px;
        border-radius: 20px;

        @media screen and (min-width: 768px) {
            font-size: 20px;
            height: 56px;
            border-radius: 28px;
            width: 250px;
        }
    }

    @media screen and (min-width: 768px) {
        h3 {
            font-size: 40px;
            width: 500px;
            margin: 0 auto;
        }
    }
}
