#header {
    width: 90vw;
    padding: 1.5rem 0 0;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

    #back-btn {
        position: unset;
        background-color: rgba(86, 204, 242, 0.75);
        height: 3rem;
        width: 3rem;
        padding: 0;
        border-radius: 50%;
        align-self: center;
    }

    #progress {
        flex-grow: 0.9;
        align-self: center;
        height: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        span {
            font-family: 'Montserrat Medium';
            font-size: 15px;
        }
    }

    @media screen and (min-width: 768px) {
        > div {
            width: 500px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
